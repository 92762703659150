import { convertToReadable } from '@leapfinance/frontend-commons/helpers';
import {
  GBTooltip,
  GBTooltipTheme,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import classNames from 'classnames';

import { GetCallHistoryLogsResponseType } from '@/services/cspCallingTargetApi';
import { Roles } from '@/types/global';
import { definitions } from '@/types/schema';

import CustomAudioPlayer from './CustomAudioPlayer';

export type CallStatus = Exclude<
  Exclude<
    GetCallHistoryLogsResponseType['data'],
    undefined
  >['callLogs'][number]['calls'],
  undefined
>[number]['callStatus'];

export type CallType = Exclude<
  Exclude<
    GetCallHistoryLogsResponseType['data'],
    undefined
  >['callLogs'][number]['calls'],
  undefined
>[number]['callType'];

export const allowedCallHistoryPageRoles = [
  Roles.counsellor,
  Roles.countryHead,
  Roles.gbInvoicing,
  Roles.opsManager,
  Roles.qc,
  Roles.representative,
  Roles.viewer,
  Roles.visaTeam,
  Roles.zonalManager,
];

const CALL_STATUS_CONFIG: Record<
  CallStatus,
  {
    icon: string;
    className: string;
  }
> = {
  ANSWERED: {
    icon: `fi-sr-phone-call`,
    className: `text-gbTokens-success-600`,
  },
  MISSED: {
    icon: `fi-sr-call-missed`,
    className: `text-gbTokens-error-600`,
  },
  REJECTED: {
    icon: `fi-sr-phone-cross`,
    className: `text-gbTokens-error-600`,
  },
  BUSY: {
    icon: `fi-ss-call-duration`,
    className: ``,
  },
  UNKNOWN: {
    icon: `fi-ss-interrogation`,
    className: ``,
  },
} as const;

const CALL_TYPE_CONFIG = {
  INCOMING: {
    icon: `fi-sr-call-incoming`,
    className: `text-gbTokens-grey-700`,
  },
  OUTGOING: {
    icon: `fi-sr-call-outgoing`,
    className: `text-gbTokens-grey-700`,
  },
} as const;

const callStatusMapping = (status: CallStatus) => {
  const config = CALL_STATUS_CONFIG[status];

  return (
    <GBTypography
      variant="body4"
      className={classNames(
        `line-clamp-1 flex items-center gap-2`,
        config?.className,
      )}
    >
      <i className={`fi ${config?.icon}`}></i>
      {convertToReadable(status ?? ``)}
    </GBTypography>
  );
};

const renderCallAttribute = <T extends CallStatus | CallType>(
  attributes: T | T[],
  mapper: (attr: T) => React.ReactNode,
  columnIndex?: number,
): React.ReactNode => {
  if (Array.isArray(attributes)) {
    return (
      <div className="flex flex-col w-full h-fit !p-0">
        {attributes?.map((element, index: number) => (
          <div
            key={index}
            className={classNames(
              `w-full px-6 py-4 border-gbTokens-grey-A400 line-clamp-1`,
              {
                'border-t': index !== 0,
                'border-t-0': index === 0,
              },
            )}
          >
            <GBTooltip
              title={convertToReadable(element ?? ``)}
              tooltipTheme={GBTooltipTheme.Dark}
              PopperProps={{
                placement: columnIndex === 0 ? `right` : `left`,
              }}
            >
              <div>{mapper(element)}</div>
            </GBTooltip>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div>
      <GBTooltip
        title={attributes}
        tooltipTheme={GBTooltipTheme.Dark}
        PopperProps={{
          placement: columnIndex === 0 ? `right` : `left`,
        }}
      >
        <div>{mapper(attributes)}</div>
      </GBTooltip>
    </div>
  );
};

const callTypeMapping = (
  type: Exclude<
    definitions['CallLogResponseDto']['calls'],
    undefined
  >[number]['callType'],
) => {
  const config = CALL_TYPE_CONFIG[type];

  return (
    <GBTypography
      variant="body4"
      className={classNames(
        `line-clamp-1 flex items-center gap-2`,
        config?.className,
      )}
    >
      <i className={`fi ${config?.icon}`}></i>
      {convertToReadable(type ?? ``)}
    </GBTypography>
  );
};

export const renderCallStatus = (
  statuses: CallStatus[],
  columnIndex?: number,
) => renderCallAttribute(statuses, callStatusMapping, columnIndex);

export const renderCallType = (types: CallType[], columnIndex?: number) =>
  renderCallAttribute(types, callTypeMapping, columnIndex);

export const renderAudioPlayer = ({
  value,
  initialDuration,
}: {
  value?: string[];
  initialDuration: number[];
}) => {
  if (!value) {
    return <div className="flex w-full items-center justify-center">-</div>;
  }

  return (
    <div className="flex flex-col w-full items-center h-full !p-0">
      {value?.map((element: any, index: number) => (
        <div
          key={index}
          className={classNames(
            `flex w-full px-6 py-2 h-full border-gbTokens-grey-A400 line-clamp-1 items-center`,
            {
              'border-t': index !== 0,
              'border-t-0': index === 0,
            },
          )}
        >
          <CustomAudioPlayer
            src={element}
            initialDuration={initialDuration?.[index] as any}
          />
        </div>
      ))}
    </div>
  );
};

export const formatDuration = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds.toString().padStart(2, `0`)}`;
};
