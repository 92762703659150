import { DocInfoModalType } from '@leapfinance/frontend-commons/components/DocInfoModal';
import { OptionType } from 'lib/constant';
import { SelectData } from 'mui-rff';
import { SummaryCardTitle } from 'types/summaryDashboard';

import { NotificationCategory } from '@/components/Notifications';
import { definitions } from '@/types/schema';
import { UpdateExamInfoRequestType } from '@/types/Services';

import { Roles } from './userManagementPermissions';

export const STAGE_LEVEL_SUMMARY_CARD_STYLING_CONFIG: Record<
  string,
  Omit<SummaryCardTitle, 'titleText'>
> = {
  'Actions Taken': {
    tooltipText: `Action taken by you in current week/current month`,
    titleTextColor: `text-secondary-800`,
    tooltipFillColor: `#8B2C2C`,
    titleBackgroundColor: `#CCDFC5`,
  },
  'Actions Required': {
    tooltipText: `Please take actions on the below applications. Ideally these numbers should reach zero or as low as possible.`,
    titleTextColor: `text-error-600`,
    tooltipFillColor: `#8B2C2C`,
    titleBackgroundColor: `bg-error-100`,
  },
  'Pending At CSP': {
    tooltipText: `CSP team will take action on the below applications. Ideally these numbers should reach zero or as low as possible. Kindly request CSP to act on these applications.`,
    titleTextColor: `text-secondary-600`,
    tooltipFillColor: `#8B2C2C`,
    titleBackgroundColor: `bg-warning-100`,
  },
  Others: {
    tooltipText: `No action is required on the below application or action is pending at the institute. The update on the application will be done post communication from the institute.`,
    titleTextColor: `text-primary-600`,
    tooltipFillColor: `#8B2C2C`,
    titleBackgroundColor: `bg-primary-200`,
  },
};

export const DATE_TIME_FIELD_MAP = {
  receivedAt: {
    label: `App Received Date`,
    from: `appReceiveDateFrom`,
    to: `appReceiveDateTo`,
  },
  updatedAt: {
    label: `Last Updated`,
    from: `lastUpdatedFrom`,
    to: `lastUpdatedTo`,
  },
};

export const genderList = [
  {
    label: `Male`,
    value: `MALE`,
  },
  {
    label: `Female`,
    value: `FEMALE`,
  },
  {
    label: `Others`,
    value: `OTHERS`,
  },
] as any;

export const maritalStatusList = [
  {
    label: `Single`,
    value: `SINGLE`,
  },
  {
    label: `Married`,
    value: `MARRIED`,
  },
] as any;

export const applicationTypes: { label: string; value: string }[] = [
  {
    label: `Agent Portal`,
    value: `AGENT_PORTAL`,
  },
  {
    label: `Online Application`,
    value: `ONLINE_APPLICATION`,
  },
  {
    label: `Third Party Application`,
    value: `THIRD_PARTY_APPLICATION`,
  },
  {
    label: `Paper Based Application`,
    value: `PAPER_BASED_APPLICATION`,
  },
  {
    label: `Email`,
    value: `EMAIL`,
  },
  {
    label: `To be filed by Geebee`,
    value: `TO_BE_FILED_BY_GEEBEE`,
  },
];

export const mandatoryStudDocs: DocInfoModalType[] = [
  {
    documentName: `Education Documents`,
    subDocument: [
      {
        label: `10th Marksheet`,
      },
      {
        label: `12th Marksheet`,
      },
      {
        label: `Highest Degree Certificates and Marksheets`,
      },
    ],
  },
  {
    documentName: `Work Experience Documents`,
    subDocument: [
      {
        label: `Resume`,
      },
      {
        label: `Work Experience`,
      },
      {
        label: `LOR`,
      },
    ],
  },
  {
    documentName: `English Exam Scores`,
    subDocument: [
      {
        label: `English Exam Scorecard`,
      },
    ],
  },
  {
    documentName: `Other Documents`,
    subDocument: [
      {
        label: `Consolidated Passport`,
      },
      {
        label: `GRE/GMAT Scorecards`,
      },
    ],
  },
];

export const documentPendingReasonOptionsNew: Array<OptionType> = [
  {
    label: `EMERGENCY CONTACT`,
    value: `EMERGENCY_CONTACT`,
  },
  {
    label: `VISA`,
    value: `VISA`,
  },
  {
    label: `PASSPORT FRONT`,
    value: `PASSPORT_FRONT`,
  },
  {
    label: `PASSPORT BACK`,
    value: `PASSPORT_BACK`,
  },
  {
    label: `TENTH MARK SHEET`,
    value: `TENTH_MARK_SHEET`,
  },
  {
    label: `TWELFTH MARK SHEET`,
    value: `TWELFTH_MARK_SHEET`,
  },
  {
    label: `UG DMC`,
    value: `UG_DMC`,
  },
  {
    label: `UG CONSOLIDATED MARK SHEET`,
    value: `UG_CONSOLIDATED_MARK_SHEET`,
  },
  {
    label: `UG PASSING CERT`,
    value: `UG_PASSING_CERT`,
  },
  {
    label: `PG DMC`,
    value: `PG_DMC`,
  },
  {
    label: `PG CONSOLIDATED MARK SHEET`,
    value: `PG_CONSOLIDATED_MARK_SHEET`,
  },
  {
    label: `IELTS SCORE CARD`,
    value: `IELTS_SCORE_CARD`,
  },
  {
    label: `WORK EX`,
    value: `WORK_EX`,
  },
  {
    label: `RESUME`,
    value: `RESUME`,
  },
  {
    label: `COUNSELING OTHER`,
    value: `COUNSELING_OTHER`,
  },
  {
    label: `CONDITIONAL OFFER`,
    value: `CONDITIONAL_OFFER`,
  },
  {
    label: `FIXED DEPOSIT`,
    value: `FIXED_DEPOSIT`,
  },
  {
    label: `CAS SUPPORTING CERTIFICATE`,
    value: `CAS_SUPPORTING_CERTIFICATE`,
  },
  {
    label: `BANK STATEMENT`,
    value: `BANK_STATEMENT`,
  },
  {
    label: `OLD PASSPORT`,
    value: `OLD_PASSPORT`,
  },
  {
    label: `PASSPORT USED PAGES`,
    value: `PASSPORT_USED_PAGES`,
  },
  {
    label: `CAS LETTER`,
    value: `CAS_LETTER`,
  },
  {
    label: `BIRTH CERTIFICATE`,
    value: `BIRTH_CERTIFICATE`,
  },
  {
    label: `PARENTS NOC`,
    value: `PARENTS_NOC`,
  },
  {
    label: `TB SCREENING`,
    value: `TB_SCREENING`,
  },
  {
    label: `OLD PASSPORT FRONT`,
    value: `OLD_PASSPORT_FRONT`,
  },
  {
    label: `UG FIRST SEM MARK SHEET`,
    value: `UG_FIRST_SEM_MARK_SHEET`,
  },
  {
    label: `UG SECOND SEM MARK SHEET`,
    value: `UG_SECOND_SEM_MARK_SHEET`,
  },
  {
    label: `UG THIRD SEM MARK SHEET`,
    value: `UG_THIRD_SEM_MARK_SHEET`,
  },
  {
    label: `UG FOURTH SEM MARK SHEET`,
    value: `UG_FOURTH_SEM_MARK_SHEET`,
  },
  {
    label: `UG FIFTH SEM MARK SHEET`,
    value: `UG_FIFTH_SEM_MARK_SHEET`,
  },
  {
    label: `UG SIX SEM MARK SHEET`,
    value: `UG_SIX_SEM_MARK_SHEET`,
  },
  {
    label: `UG SEVEN SEM MARK SHEET`,
    value: `UG_SEVEN_SEM_MARK_SHEET`,
  },
  {
    label: `UG EIGHT SEM MARK SHEET`,
    value: `UG_EIGHT_SEM_MARK_SHEET`,
  },
  {
    label: `UG NINTH SEM MARK SHEET`,
    value: `UG_NINTH_SEM_MARK_SHEET`,
  },
  {
    label: `UG TENTH SEM MARK SHEET`,
    value: `UG_TENTH_SEM_MARK_SHEET`,
  },
  {
    label: `PG FIRST SEM MARK SHEET`,
    value: `PG_FIRST_SEM_MARK_SHEET`,
  },
  {
    label: `PG SECOND SEM MARK SHEET`,
    value: `PG_SECOND_SEM_MARK_SHEET`,
  },
  {
    label: `PG THIRD SEM MARK SHEET`,
    value: `PG_THIRD_SEM_MARK_SHEET`,
  },
  {
    label: `PG FOURTH SEM MARK SHEET`,
    value: `PG_FOURTH_SEM_MARK_SHEET`,
  },
  {
    label: `PG FIFTH SEM MARK SHEET`,
    value: `PG_FIFTH_SEM_MARK_SHEET`,
  },
  {
    label: `PG SIX SEM MARK SHEET`,
    value: `PG_SIX_SEM_MARK_SHEET`,
  },
  {
    label: `UG FIRST YEAR`,
    value: `UG_FIRST_YEAR`,
  },
  {
    label: `UG SECOND YEAR`,
    value: `UG_SECOND_YEAR`,
  },
  {
    label: `UG THIRD YEAR`,
    value: `UG_THIRD_YEAR`,
  },
  {
    label: `UG FOURTH YEAR`,
    value: `UG_FOURTH_YEAR`,
  },
  {
    label: `UG FIFTH YEAR`,
    value: `UG_FIFTH_YEAR`,
  },
  {
    label: `PG FIRST YEAR`,
    value: `PG_FIRST_YEAR`,
  },
  {
    label: `PG SECOND YEAR`,
    value: `PG_SECOND_YEAR`,
  },
  {
    label: `PG THIRD YEAR`,
    value: `PG_THIRD_YEAR`,
  },
  {
    label: `CONSOLIDATED PASSPORT`,
    value: `CONSOLIDATED_PASSPORT`,
  },
  {
    label: `PG PASSING CERT`,
    value: `PG_PASSING_CERT`,
  },
  {
    label: `IELTS TEST DETAILS`,
    value: `IELTS_TEST_DETAILS`,
  },
  {
    label: `SOP`,
    value: `SOP`,
  },
  {
    label: `LOR`,
    value: `LOR`,
  },
  {
    label: `TOEFL SCORE CARD`,
    value: `TOEFL_SCORE_CARD`,
  },
  {
    label: `VISA APPLICATION FORM`,
    value: `VISA_APPLICATION_FORM`,
  },
  {
    label: `PHOTOGRAPH`,
    value: `PHOTOGRAPH`,
  },
  {
    label: `UNCONDITIONAL OFFER FROM COLLEGE`,
    value: `UNCONDITIONAL_OFFER_FROM_COLLEGE`,
  },
  {
    label: `FEE PAYMENT RECEIPT`,
    value: `FEE_PAYMENT_RECIPT`,
  },
  {
    label: `PRESENT WORK APPOINTMENT LETTER`,
    value: `PRESENT_WORK_APPOINTMENT_LETTER`,
  },
  {
    label: `FORM 16`,
    value: `FORM_16`,
  },
  {
    label: `MEDICAL CERTIFICATE`,
    value: `MEDICAL_CERTIFICATE`,
  },
  {
    label: `FINANCIAL DOCUMENT`,
    value: `FINANCIAL_DOCUMENT`,
  },

  {
    label: `SPONSOR FINANCIAL DOCUMENT`,
    value: `SPONSOR_FINANCIAL_DOCUMENT`,
  },
  {
    label: `EXTRA CURRICULUM CERTIFICATE`,
    value: `EXTRA_CARRICULUM_CERTIFICATE`,
  },
  {
    label: `POLICE CLEARANCE CERTIFICATE`,
    value: `POLICE_CLEARANCE_CERTIFICATE`,
  },
  {
    label: `SPONSOR CA CERTIFICATE`,
    value: `SPONSOR_CA_CERTIFICATE`,
  },
  {
    label: `SPONSOR AFFIDAVIT OF SUPPORT`,
    value: `SPONSOR_AFFIDAVIT_OF_SUPPORT`,
  },
  {
    label: `SPONSOR RELATIONSHIP PROOF`,
    value: `SPONSOR_RELATIONSHIP_PROOF`,
  },
  {
    label: `VISA FEE RECEIPT`,
    value: `VISA_FEE_RECIPT`,
  },
  {
    label: `IHS CERTIFICATE`,
    value: `IHS_CERTIFICATE`,
  },
  {
    label: `BACKLOG LETTER`,
    value: `BACKLOG_LETTER`,
  },
  {
    label: `VISA APPOINTMENT CONFIRMATION`,
    value: `VISA_APPOINTMENT_CONFIRMATION`,
  },
  {
    label: `UNDER 18 CONSENT`,
    value: `UNDER_18_CONSENT`,
  },
  {
    label: `GIC CERTIFICATE`,
    value: `GIC_CERTIFICATE`,
  },
  {
    label: `EDUCATION LOAN LETTER`,
    value: `EDUCATION_LOAN_LETTER`,
  },
  {
    label: `PPF WITHDRAWL LETTER`,
    value: `PPF_WITHDRAWL_LETTER`,
  },
  {
    label: `PPF PASSBOOK STATEMENT`,
    value: `PPF_PASSBOOK_STATEMENT`,
  },
  {
    label: `AFFIDAVIT SUPPORT OF SPONSOR`,
    value: `AFFIDAVIT_SUPPORT_OF_SPONSOR`,
  },
  {
    label: `RELATIONSHIP ID PROOF SPONSOR`,
    value: `RELATIONSHIP_ID_PROOF_SPONSOR`,
  },
  {
    label: `FORM NO 16 SPONSOR`,
    value: `FORM_NO_16_SPONSOR`,
  },
  {
    label: `INCOME TAX PARENTS`,
    value: `INCOME_TAX_PARENTS`,
  },
  {
    label: `QUEBEC CLEARANCE FORM`,
    value: `QUEBEC_CLEARANCE_FORM`,
  },
  {
    label: `DIGITAL PHOTO`,
    value: `DIGITAL_PHOTO`,
  },
  {
    label: `MARRIAGE CERTIFICATE`,
    value: `MARRIAGE_CERTIFICATE`,
  },
  {
    label: `VISA REFUSAL DOCUMENT`,
    value: `VISA_REFUSAL_DOCUMENT`,
  },
  {
    label: `INTERNSHIP CERTIFICATE`,
    value: `INTERNSHIP_CERTIFICATE`,
  },
  {
    label: `APPRAISAL CERTIFICATE`,
    value: `APPRAISAL_CERTIFICATE`,
  },
  {
    label: `TRANSCRIPT HIGHER EDUCATION`,
    value: `TRANSCRIPT_HIGHER_EDUCATION`,
  },
  {
    label: `TRANSCRIPT GRADUATION`,
    value: `TRANSCRIPT_GRADUATION`,
  },
  {
    label: `VISA APPLICATION FORM FAMILY`,
    value: `VISA_APPLICATION_FORM_FAMILY`,
  },
  {
    label: `VISA APPLICATION FORM MAIN`,
    value: `VISA_APPLICATION_FORM_MAIN`,
  },
  {
    label: `VISA APPLICATION FORM TEMPORARY RESIDENCE`,
    value: `VISA_APPLICATION_FORM_TEMPORARY_RESIDENCE`,
  },
  {
    label: `BANK STATEMENT SALARY`,
    value: `BANK_STATEMENT_SALARY`,
  },
  {
    label: `OLD PASSPORT BACK`,
    value: `OLD_PASSPORT_BACK`,
  },
  {
    label: `OFFER LETTER`,
    value: `OFFER_LETTER`,
  },
  {
    label: `LOR 2`,
    value: `LOR_2`,
  },
  {
    label: `LOR 3`,
    value: `LOR_3`,
  },
  {
    label: `UNCONDITIONAL_OFFER_LETTER`,
    value: `UNCONDITIONAL_OFFER_LETTER`,
  },
  {
    label: `CONDITIONAL_OFFER_LETTER`,
    value: `CONDITIONAL_OFFER_LETTER`,
  },
  { label: `LOR_4`, value: `LOR_4` },
  {
    label: `SUBMISSION_ACKNOWLEDGEMENT`,
    value: `SUBMISSION_ACKNOWLEDGEMENT`,
  },
  { label: `AUTHORISATION`, value: `AUTHORISATION` },
  { label: `DIRECT_ENTRY_WAIVER`, value: `DIRECT_ENTRY_WAIVER` },
  { label: `APPLICATION_FORM`, value: `APPLICATION_FORM` },
  { label: `DIRECT_FEE_RECEIPT`, value: `DIRECT_FEE_RECEIPT` },
  { label: `ECOE`, value: `ECOE` },
  { label: `I20`, value: `I20` },
  { label: `LOA`, value: `LOA` },
  { label: `FTS`, value: `FTS` },
  { label: `ACADEMIC_DOCUMENTS`, value: `ACADEMIC_DOCUMENTS` },
  { label: `AIP_LETTER`, value: `AIP_LETTER` },
  { label: `PPR_LETTER`, value: `PPR_LETTER` },
  { label: `FLYWIRE`, value: `FLYWIRE` },
  { label: `BANK_DRAFT`, value: `BANK_DRAFT` },
  { label: `CREDIT_CARD_RECEIPT`, value: `CREDIT_CARD_RECEIPT` },
  { label: `TT`, value: `TT` },
  { label: `CONSENT_FORM`, value: `CONSENT_FORM` },
  { label: `AUTHORITY_LETTER`, value: `AUTHORITY_LETTER` },
  { label: `LOGIN_CREDENTIALS`, value: `LOGIN_CREDENTIALS` },
  {
    label: `SUBMISSION_ACKNOWLEDGEMENT_PARTNER`,
    value: `SUBMISSION_ACKNOWLEDGEMENT_PARTNER`,
  },
  {
    label: `PAYMENT_ACKNOWLEDGEMENT_RECEIPT`,
    value: `PAYMENT_ACKNOWLEDGEMENT_RECEIPT`,
  },
  {
    label: `ADMIT_ACCEPTANCE_LETTER`,
    value: `ADMIT_ACCEPTANCE_LETTER`,
  },
  {
    label: `PROVISIONAL_DEGREE_CERTIFICATE`,
    value: `PROVISIONAL_DEGREE_CERTIFICATE`,
  },
  { label: `COMMUNICATION_CONSENT`, value: `COMMUNICATION_CONSENT` },
  {
    label: `VISA_FILING_ACKNOWLEDGEMENT`,
    value: `VISA_FILING_ACKNOWLEDGEMENT`,
  },
  { label: `PASSPORT_STAMPED_COPY`, value: `PASSPORT_STAMPED_COPY` },
  {
    label: `SUPPLEMANTARY_INFORMATION_FORM`,
    value: `SUPPLEMANTARY_INFORMATION_FORM`,
  },
  {
    label: `AVATS_FORM_CONFIRMATION`,
    value: `AVATS_FORM_CONFIRMATION`,
  },
  { label: `AVATS_ONLINE_FORM`, value: `AVATS_ONLINE_FORM` },
  { label: `COVER_LETTER`, value: `COVER_LETTER` },
  { label: `HEALTH_INSURANCE`, value: `HEALTH_INSURANCE` },
  {
    label: `POLICE_CLEARANCE_CERTIFICATE`,
    value: `POLICE_CLEARANCE_CERTIFICATE`,
  },
  { label: `SWIFT_DETAILS`, value: `SWIFT_DETAILS` },
  { label: `SALARY_ACCOUNT_BALANCE`, value: `SALARY_ACCOUNT_BALANCE` },
  {
    label: `BANK_BALANCE_CONFIRMATION`,
    value: `BANK_BALANCE_CONFIRMATION`,
  },
  { label: `FIXED_DEPOSIT_RECIEPT`, value: `FIXED_DEPOSIT_RECIEPT` },
  { label: `LOAN_SANCTION_LETTER`, value: `LOAN_SANCTION_LETTER` },
  { label: `PPF_WITHDRAWAL_LETTER`, value: `PPF_WITHDRAWAL_LETTER` },
  { label: `PROPERTY_MORTGAGE_DOC`, value: `PROPERTY_MORTGAGE_DOC` },
  { label: `AADHAR_CARD`, value: `AADHAR_CARD` },
  {
    label: `BUSINESS_REGISTRATION_LETTER`,
    value: `BUSINESS_REGISTRATION_LETTER`,
  },
  { label: `BACKLOG_MARKSHEET`, value: `BACKLOG_MARKSHEET` },
  { label: `OSHC`, value: `OSHC` },
  {
    label: `APPOINTMENT_CONFIRMATION`,
    value: `APPOINTMENT_CONFIRMATION`,
  },
  {
    label: `SPONSOR_INCOME_TAX_RETURN`,
    value: `SPONSOR_INCOME_TAX_RETURN`,
  },
  { label: `DS160`, value: `DS160` },
  { label: `USA_TRAVEL_DOC`, value: `USA_TRAVEL_DOC` },
  {
    label: `VISA_FEE_PAYMENT_RECIEPT`,
    value: `VISA_FEE_PAYMENT_RECIEPT`,
  },
  { label: `ADMIT_REJECTED_LETTER`, value: `ADMIT_REJECTED_LETTER` },
  {
    label: `ACCOMODATION_CONFIRMATION`,
    value: `ACCOMODATION_CONFIRMATION`,
  },
  {
    label: `MEDIUM_OF_INSTRUCTION_LETTER`,
    value: `MEDIUM_OF_INSTRUCTION_LETTER`,
  },
  { label: `VISA_SOP`, value: `VISA_SOP` },
  { label: `FORM_157A`, value: `FORM_157A` },
  { label: `FORM_956`, value: `FORM_956` },
];

export const ADD_APPLICATION_USER_TYPES = [
  Roles.admin,
  Roles.countryHead,
  Roles.counsellor,
  Roles.opsManager,
  Roles.representative,
  Roles.viewer,
];

export const ADD_STUDENT_USER_TYPES = [
  Roles.admin,
  Roles.countryHead,
  Roles.counsellor,
  Roles.opsManager,
  Roles.representative,
  Roles.visaTeam,
  Roles.viewer,
];

export const STUDENT_DETAILS_PAGE_URL = `/student/`;
export const GB_REPRESENTATIVE = `GB_REPRESENTATIVE`;

export const SAVE_PAYMENT_SECTION_BUTTON = `SAVE_PAYMENT_SECTION_BUTTON`;
export const relationShipTypes: SelectData[] = [
  {
    label: `Father`,
    value: `father`,
  },
  {
    label: `Mother`,
    value: `mother`,
  },
  {
    label: `Sibling`,
    value: `sibling`,
  },
  {
    label: `Others`,
    value: `others`,
  },
];

export const STUDENT_PROFILE_OPTIONS = [
  { label: `Brilliant`, value: `BRILLIANT` },
  { label: `Average`, value: `AVERAGE` },
  { label: `Decent`, value: `DECENT` },
];

export enum STUDENT_PROFILE_ENUM {
  Brilliant = `BRILLIANT`,
  Average = `AVERAGE`,
  Decent = `DECENT`,
}

export const STUDENT_PROFILE_COLOR_MAP = {
  [STUDENT_PROFILE_ENUM.Average]: {
    studentProfilePillColorScheme: {
      textColor: `text-primary-500`,
      bgColor: `bg-primary-100`,
    },
  },
  [STUDENT_PROFILE_ENUM.Brilliant]: {
    studentProfilePillColorScheme: {
      textColor: `text-success-500`,
      bgColor: `bg-success-100`,
    },
  },
  [STUDENT_PROFILE_ENUM.Decent]: {
    studentProfilePillColorScheme: {
      textColor: `text-warning-500`,
      bgColor: `bg-warning-100`,
    },
  },
};

export const getBrowserNotificationTitle = (templateIdentifier?: string) => {
  switch (templateIdentifier) {
    case NotificationCategory.remarks:
      return `A new remark has been added`;
    case NotificationCategory.state:
      return `Status of application is changed`;
    case NotificationCategory.document:
      return `A new document is uploaded`;
    default:
      return `A new update has happened`;
  }
};

export enum BrowserNotificationAccessStatus {
  GRANTED = `granted`,
  DENIED = `denied`,
  DEFAULT = `default`,
}

export enum ComponentIdMapper {
  NOTIFICATION_DISPLAY = `notifications`,
}

export enum ActiveTabLabelIndexMapper {
  MY_TASK = 0,
  PERFORMANCE_TRACKER = 1,
}

export enum CounsellorActiveTabLabelIndexMapper {
  PENDING_TASKS = 0,
  MY_TASK = 1,
  PERFORMANCE_TRACKER = 2,
}

export const ApplicationCompletenessStatusTypes = {
  MAYBE_INCOMPLETE: `MAYBE_INCOMPLETE`,
  COMPLETE_APPLICATION: `COMPLETE_APPLICATION`,
  ALREADY_SUBMITTED: `ALREADY_SUBMITTED`,
};

export enum COMPONENT_ID_ENUM {
  payments = `payments`,
  shortlists = `shortlists`,
  REQUIRED_DOCUMENT_SECTION = `required-document-section`,
  CONDITIONAL_SECTION = `conditional-section`,
  DEFERRAL_SECTION = `deferral-section`,
  DEFERRAL_PLACEHOLDER = `deferral-placeholder`,
  SHORTLIST_DOCUMENTS = `shortlist-documents`,
  CF_FILTER_SIDEBAR = `cf-filter-sidebar`,
  CF_FOOTER = `cf-footer`,
  APPLICATION_DETAILS_SECTION = `application-details-section`,
  APPLICATION_STATUS_INFO_SECTION = `application-status-info-section`,
  STUDENT_ELIGIBILTY_SECTION = `student-eligibility-section`,
  NEW_DASHBOARD_FILTERS = `NEW_DASHBOARD_FILTERS`,
  NEW_DASHBOARD_PENDING_ACTIONS_SUMMARY = `NEW_DASHBOARD_PENDING_ACTIONS_SUMMARY`,
  NEW_DASHBOARD_PERFORMANCE_SECTION = `NEW_DASHBOARD_PERFORMANCE_SECTION`,
  NEW_DASHBOARD_NEWSLETTER_SECTION = `NEW_DASHBOARD_NEWSLETTER_SECTION`,
  NEW_DASHBOARD_QUICK_LINKS_SECTION = `NEW_DASHBOARD_QUICK_LINKS_SECTION`,
  CF_USP_FILTER_CHIPS_CONTAINER = `CF_USP_FILTER_CHIPS_CONTAINER`,
  CF_APPLIED_FILTERS = `cf-applied-filters`,
  CF_GLOBAL_SEARCH_BANNER = `cf-global-search-banner`,
  CF_RESULT_SECTION = `cf-result-section`,
  NO_RESULTS_REASON_SECTION = `no-result-reasons`,
  CF_ELIGIBLITY_FILTER = `cf-eligiblity-filter`,
  ADMIN_TASK_PREVIEW = `ADMIN_TASK_PREVIEW`,
  DASHBOARD_SIDE_PANEL_CONTAINER = `DASHBOARD_SIDE_PANEL_CONTAINER`,
  TOP_FILTERS = `top-filters`,
  TOP_FILTERS_CONTAINER = `top-filters-container`,
  SHORTLIST_REQUEST_SCROLL_TO = `SHORTLIST_REQUEST_SCROLL_TO`,
  UNASSIGNED_EMAILS_CONATIENR = `UNASSIGNED_EMAILS_CONATIENR`,
  TASK_LEADERBOARD_SCROLL_CONTAINER = `TASK_LEADERBOARD_SCROLL_CONTAINER`,
  CSP_ONBOARDING_LAYOUT = `csp-on-boarding-layout`,
  KNOWLEDGE_LIBRARY_SCROLL_TO_TOP = `KNOWLEDGE_LIBRARY_SCROLL_TO_TOP`,
  CONDITIONS_LIST_CONTAINER = `conditions-list-container`,
  TASK_PREVIEW_LIST = `TASK_PREVIEW_LIST`,
}

export const microsoftDocuments = `.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document`;

export const DISABLED_CONDITIONAL_REASONS = [
  `BANK_STATEMENT`,
  `FINANCIAL_DOCUMENT`,
];

export enum QueryParams {
  SHOW_REPORT_INACCURATE_DATA_FIELD_MODAL = `showReportInaccurateDataFieldModal`,
  APPLY_DASHBOARD_FILTERS = `applyDashboardFilters`,
  OPEN_FIRST_DOCUMENT = `openFirstDocument`,
  ELIGIBILITY_DATA = `eligibilityData`,
  CSP_ID = `cspId`,
  COHORT_TYPE = `cohortType`,
}

export const postStiStagesList = [
  `APPLICATION_SENT_TO_UNIVERSITY`,
  `APPLICATION_ON_HOLD_BY_UNIVERSITY`,
  `UNIVERSITY_ON_HOLD_RESOLVED`,
  `UNIVERSITY_COMMUNICATION_PENDING`,
  `APPLICATION_DROPPED`,
  `APPLICATION_REJECTED`,
  `CONDITIONAL_ADMIT_RECEIVED`,
  `UNCONDITIONAL_ADMIT_RECEIVED`,
  `ADMIT_DECLINED`,
  `ADMIT_ACCEPTED`,
  `TUITION_FEE_PAID`,
  `DEPOSIT_PAID`,
  `OFFER_REVOKED`,
];

export const postOfferStagesList = [
  `CONDITIONAL_ADMIT_RECEIVED`,
  `ADMIT_ACCEPTED`,
  `ADMIT_DECLINED`,
  `UNCONDITIONAL_ADMIT_RECEIVED`,
  `APPLICATION_REJECTED`,
  `OFFER_REVOKED`,
];

export enum ShortlistedCourseSourceTypesEnum {
  CF_SEARCH_RESULT = `CF_SEARCH_RESULT`,
  ADD_MANUALLY_CF_SUGGESTION = `ADD_MANUALLY_CF_SUGGESTION`,
  ADD_NEW_COURSE_MANUALLY = `ADD_NEW_COURSE_MANUALLY`,
  RECOMMENDED = `RECOMMENDED`,
}

export enum RemarkTaskStatusEnum {
  TASK_CREATED = `TASK_CREATED`,
  QUERY_RESOLVED = `QUERY_RESOLVED`,
  IRRELEVANT_TASK = `IRRELEVANT_TASK`,
}

export enum LocalStorageEnum {
  LAST_COURSE_REPORT_MODAL_SHOWN_AT = `last-course-report-modal-shown-at`,
}

export const convertDaysToWeeks = (days: number | undefined) => {
  if (!days || Number.isNaN(days) || Number(days) < 0) {
    return;
  }
  if (days < 7) {
    if (days === 1) {
      return `1 Day`;
    }
    return `${days} Days`;
  }
  const convertedWeeks = Math.round(days / 7);

  if (convertedWeeks === 1) {
    return `1 Week`;
  }
  return `${convertedWeeks} Weeks`;
};

export enum LocalStorageKeysEnum {
  DASHBOARD_NAVIGATION_FILTERS = `DASHBOARD_NAVIGATION_FILTERS`,
  CSP_PERFORMANCE_DASHBOARD_NAVIGATION_FILTERS = `CSP_PERFORMANCE_DASHBOARD_NAVIGATION_FILTERS`,
}

export type ExamType = Exclude<
  Exclude<
    Exclude<UpdateExamInfoRequestType[`examInfoDetails`], undefined>[number],
    undefined
  >[`examType`],
  undefined
>;
export const ExamDocumentKeys: Record<
  ExamType,
  definitions[`DocumentDto`][`keyName`]
> = {
  TENTH: `TENTH_MARK_SHEET`,
  TWELFTH: `TWELFTH_MARK_SHEET`,
  DIPLOMA: `DIPLOMA_MARK_SHEET`,
  PG_DIPLOMA: `PGD_MARK_SHEET`,
  BACHELORS_DEGREE: `BACHELORS_DEGREE`,
  MASTERS_DEGREE: `MASTERS_DEGREE`,
  PHD: `PHD_MARK_SHEET`,
  RESUME: `RESUME`,
  WORK_EXPERIENCE: `WORK_EXPERIENCE`,
  IELTS: `IELTS_SCORE_CARD`,
  TOEFL: `TOEFL_SCORE_CARD`,
  PTE: `PTE_SCORE_CARD`,
  DUOLINGO: `DUOLINGO_SCORE_CARD`,
  GRE: `GRE_SCORE_CARD`,
  GMAT: `GMAT_SCORE_CARD`,
  MISCELLANEOUS: `COUNSELING_OTHER`,
  PASSPORT: `CONSOLIDATED_PASSPORT`,
  PAYMENT_PROOFS: `PAYMENT_ACKNOWLEDGEMENT_RECEIPT`,
};

export const CoventryUniversityId = 1050;
