import React, { Suspense } from 'react';
import { NoResults } from '@leapfinance/frontend-commons';
import { CircularProgress, Drawer } from '@mui/material';
import { Box, Modal } from '@mui/material';
import { ModalDataType, selectModal } from 'app/features/modals/modalsSlice';
import { useAppSelector } from 'app/hooks';
import dynamic from 'next/dynamic';

import { RootState } from '@/app/store';
import VASDrawer from '@/components/modals/Drawers/VASDrawer';
import GTEModal from '@/components/modals/GTEModal';
import SOPMoreFiltersModal from '@/components/SOP/Dashboard/SOPMoreFilters';
import SOPDraftPlagiarismModal from '@/components/SOP/SOPPlagiarism/SOPDraftPlagiarismModal';
import CSVExportModal from '@/components/UnisetuDashboard/ExportModal';
import UnisetuDashboardMoreFilters from '@/components/UnisetuDashboard/Filters/UnisetuDashboardMoreFilters';
import AddCourseToShortlist from '@/pages/course-details/AddCourseToShortlist';

import SuccessfulUpdateModal from '../AdminDashboard/Tasks/TaskDetails/SuccessfulUpdateModal';
import AddFocusedUniversityForm from '../FocusedUniversities/AddFocusedUniversityForm';

const CommissionVersionHistoryModal = dynamic(
  () => import(`../Commission/CommissionVersionHistoryModal`),
  { ssr: false },
);
const ReportCourseGlobalModal = dynamic(
  () => import(`./ReportCourseGlobalModal`),
  {
    ssr: false,
  },
);

const RecommendedCoursesModal = dynamic(
  () => import(`./RecommendedCoursesModal`),
  {
    ssr: false,
  },
);

const AddApplication = dynamic(() => import(`./AddApplication`), {
  ssr: false,
});

const AddCourse = dynamic(() => import(`./AddCourse`), {
  ssr: false,
});

const AddStudentModal = dynamic(() => import(`./AddStudentModal`), {
  ssr: false,
});

const ApplicationDocInfoModal = dynamic(
  () => import(`./ApplicationDocInfoModal`),
  {
    ssr: false,
  },
);

const DownloadApplicationCsv = dynamic(
  () => import(`./DownloadApplicationCsv`),
  {
    ssr: false,
  },
);

const PreVisaInfo = dynamic(() => import(`./PreVisaInfoModal`), {
  ssr: false,
});

const StudentDocInfoModal = dynamic(() => import(`./StudentDocInfoModal`), {
  ssr: false,
});

const UploadAndMapDocumentsModal = dynamic(
  () => import(`./UploadAndMapDocumentsModal`),
  {
    ssr: false,
  },
);

const ShortlistConditionalModal = dynamic(
  () => import(`./ShortlistConditionalModal`),
  {
    ssr: false,
  },
);

const DocumentRejectModal = dynamic(() => import(`./DocumentRejectModal`), {
  ssr: false,
});

const SimilarVisaRequestWarningModal = dynamic(
  () => import(`./SimilarVisaRequestWarning`),
);

const DeleteVisaRequestWarningModal = dynamic(
  () => import(`./DeleteVisaRequestWarningModal`),
);

const AddToBookmark = dynamic(() => import(`./AddToBookmark`), {
  ssr: false,
});

const CreateBookmarkListModal = dynamic(
  () => import(`./CreateBookmarkListModal`),
  {
    ssr: false,
  },
);

const StudentCommissionMoreFilter = dynamic(
  () =>
    import(
      `../Commission/StudentListPage/StudentListFilters/StudentCommissionMoreFilter`
    ),
);
const CommissionDuplicateStudentsModal = dynamic(
  () => import(`./CommissionDuplicateStudentsModal`),
  {
    ssr: false,
  },
);

const CommissionBulkUpdateModal = dynamic(
  () => import(`../Commission/BulkUpdateModal`),
  { ssr: false },
);
const UpdateInfoModal = dynamic(() => import(`./UpdateInfoModal`), {
  ssr: false,
});

const ShortlistRemarksModal = dynamic(() => import(`./ShortlistRemarksModal`), {
  ssr: false,
});

const CreateCMSInstituteModal = dynamic(
  () => import(`./CreateCMSInstituteModal`),
  {
    ssr: false,
  },
);
const MarkProgramClosedModal = dynamic(
  () => import(`./MarkProgramClosedModal`),
  {
    ssr: false,
  },
);

const IeltsVerificationModal = dynamic(
  () => import(`../IeltsVerification/IeltsVerificationModal`),
  {
    ssr: false,
  },
);

const TaskEmailEditorModal = dynamic(
  () => import(`./TaskEmailEditorModal/TaskEmailEditorModal`),
  {
    ssr: false,
  },
);
const AddSubAgentForm = dynamic(
  () => import(`@/components/SubAgent/AddSugAgentForm`),
  { ssr: false },
);

const DeferralHistoryModal = dynamic(
  () => import(`../../components/Deferral/DeferralHistoryModal`),
  {
    ssr: false,
  },
);

const VASMoreFiltersModal = dynamic(
  () => import(`@/components/vas/VASMoreFilters`),
  { ssr: false },
);

const MoreFiltersModal = dynamic(
  () => import(`../AdminDashboard/Tasks/Filters/MoreFiltersModal`),
  {
    ssr: false,
  },
);

const CounsellorPerformanceCspDetailsModal = dynamic(
  () => import(`./CounsellorPerformance/CounsellorPerformanceCspDetailsModal`),
  {
    ssr: false,
  },
);
const NextActionModal = dynamic(
  () => import(`../AdminDashboard/Tasks/TaskDetails/NextActionModal`),
  {
    ssr: false,
  },
);

const CounsellorInitiateJerryCall = dynamic(
  () => import(`./CounsellorPerformance/CounsellorInitiateJerryCall`),
  {
    ssr: false,
  },
);

const RaiseShortlistRequestModal = dynamic(
  () => import(`./RaiseShortlistRequest`),
  { ssr: false },
);

const EmailConfirmationModal = dynamic(
  () =>
    import(
      `../Student/Shortlist/ShortlistComponents/ShortlistFormComponents/EmailConfirmationModal`
    ),
);
const StudentDownloadFormModal = dynamic(
  () => import(`./StudentDownloadForm`),
  {
    ssr: false,
  },
);

const CspMoreFiltersModal = dynamic(
  () => import(`../CspManagement/CspMoreFilters`),
  { ssr: false },
);

const AdditionalDetailsModal = dynamic(
  () => import(`../UserManagement/AdditionalPartnerDetailsModal`),
  { ssr: false },
);

const AddMeetingModal = dynamic(
  () => import(`../MeetingTracker/AddMeetingModal`),
  {
    ssr: false,
  },
);

const CancelMeetingDialog = dynamic(
  () => import(`../MeetingTracker/CancelMeetingDialog`),
  {
    ssr: false,
  },
);

const PartnerProfileModal = dynamic(() => import(`./PartnerProfileModal`), {
  ssr: false,
});

const VoucherStatusConfirmationDialog = dynamic(
  () => import(`./VoucherStatusConfirmationDialog`),
  {
    ssr: false,
  },
);

const AddTargetModal = dynamic(
  () => import(`../TargetPageComponents/AddTargetModal`),
  {
    ssr: false,
  },
);
const ApplicationFilingQCModal = dynamic(
  () => import(`../Application/postQc/ChecklistModal`),
  { ssr: false },
);

const BulkApplicationTableModal = dynamic(
  () =>
    import(
      `../AdminDashboard/Tasks/TaskDetails/TaskDetailsEmailSection/BulkApplicationTableModal`
    ),
  { ssr: false },
);

export enum MODAL_TYPES {
  ADD_COURSE = `addCourse`,
  ADD_STUDENT = `addStudent`,
  ADD_APPLICATION = `addApplication`,
  MANDATORY_DOC = `mandatoryDocuments`,
  STUDENT_DOCUMENT_INFO = `studentDocInfoModal`,
  DOWNLOAD_APPLICATION_CSV = `downloadApplicationCsv`,
  PRE_VISA_INFO = `PRE_VISA_INFO`,
  UPLOAD_AND_MAP_DOCS = `UPLOAD_AND_MAP_DOCS`,
  DOCUMENT_REJECT_MODAL = `DOCUMENT_REJECT_MODAL`,
  SHORTLIST_CONDITIONAL_MODAL = `shortlistConditionalModal`,
  NO_RESULTS = `NO_RESULTS`,
  SIMILAR_VISA_REQUEST_WARNING_MODAL = `similarVisaRequestWarningModal`,
  DELETE_VISA_REQUEST_WARNING_MODAL = `deleteVisaRequestWarningModal`,
  ADD_BOOKMARK = `ADD_BOOKMARK`,
  CREATE_BOOKMARK_LIST_MODAL = `CREATE_BOOKMARK_LIST_MODAL`,
  COMMISSION_DUPLICATE_STUDENTS_MODAL = `COMMISSION_DUPLICATE_STUDENTS_MODAL`,
  COMMISSION_VERSION_HISTORY = `COMMISSION_VERSION_HISTORY`,
  STUDENT_COMMISSION_MORE_FILTER = `STUDENT_COMMISSION_MORE_FILTER`,
  COMMISSION_BULK_UPDATE_MODAL = `COMMISSION_BULK_UPDATE_MODAL`,
  UPDATE_INFO_MODAL = `UPDATE_INFO_MODAL`,
  SHORTLIST_REMARKS_MODAL = `SHORTLIST_REMARKS_MODAL`,
  GTE_PROCESS = `GTE_PROCESS`,
  CREATE_CMS_INSTITUTE_MODAL = `CREATE_CMS_INSTITUTE_MODAL`,
  MARK_PROGRAM_CLOSED_MODAL = `MARK_PROGRAM_CLOSED_MODAL`,
  IELTS_VERIFICATION_MODAL = `IELTS_VERIFICATION_MODAL`,
  UNISETU_DASHBOARD_MORE_FILTER = `UNISETU_DASHBOARD_MORE_FILTER`,
  CSV_EXPORT_MODAL = `CSV_EXPORT_MODAL`,
  VAS_DRAWER = `VAS_DRAWER`,
  REPORT_COURSE_GLOBAL_MODAL = `REPORT_COURSE_GLOBAL_MODAL`,
  TASK_EMAIL_EDITOR_MODAL = `TASK_EMAIL_EDITOR_MODAL`,
  ADD_SUB_AGENT_FORM = `ADD_SUB_AGENT_FORM`,
  DEFFERAL_HISTORY_MODAL = `DEFERRAL_HISTORY_MODAL`,
  RECOMMENDED_COURSES_MODAL = `RECOMMENDED_COURSES_MODAL`,
  VAS_MORE_FILTERS = `VAS_MORE_FILTERS`,
  EMAIL_AUTOMATION_MORE_FILTERS_MODAL = `EMAIL_AUTOMATION_MORE_FILTERS_MODAL`,
  COUNSELLOR_CSP_PERFORMANCE_DETAILS_MODAL = `COUNSELLOR_CSP_PERFORMANCE_DETAILS_MODAL`,
  COUNSELLOR_CSP_INITIATE_CALL = `COUNSELLOR_CSP_INITIATE_CALL`,
  NEXT_ACTION_MODAL = `NEXT_ACTION_MODAL`,
  SUCCESSFUL_UPDATE_MODAL = `SUCCESSFUL_UPDATE_MODAL`,
  STUDENT_DOWNLOAD_FORM_MODAL = `STUDENT_DOWNLOAD_FORM_MODAL`,
  ADD_COURSE_TO_SHORTLIST = `ADD_COURSE_TO_SHORTLIST`,
  RAISE_SHORTLIST_REQUEST_MODAL = `RAISE_SHORTLIST_REQUEST_MODAL`,
  EMAIL_CONFIRMATION = `EMAIL_CONFIRMATION`,
  CSP_MORE_FILTERS = `CSP_MORE_FILTERS`,
  ADDITIONAL_USER_DETAILS = `ADDITIONAL_USER_DETAILS`,
  SOP_DRAFT_PLAGIARISM_CHECK = `SOP_DRAFT_PLAGIARISM_CHECK`,
  ADD_MEETING = `ADD_MEETING`,
  CANCEL_MEETING = `CANCEL_MEETING`,
  SOP_MORE_FILTERS = `SOP_MORE_FILTERS`,
  PARTNER_PROFILE_MODAL = `PARTNER_PROFILE_MODAL`,
  VOUCHER_STATUS_CONFIRMATION_DIALOG = `VoucherStatusConfirmationDialog`,
  ADD_FOCUSED_UNIVERSITY = `ADD_FOCUSED_UNIVERSITY`,
  ADD_TARGET_MODAL = `ADD_TARGET_MODAL`,
  APPLICATION_FILING_QC_MODAL = `APPLICATION_FILING_QC_MODAL`,
  BULK_APPLICATION_TABLE_MODAL = `BULK_APPLICATION_TABLE_MODAL`,
}

const MODALS: Record<MODAL_TYPES, any> = {
  [MODAL_TYPES.ADD_COURSE]: AddCourse,
  [MODAL_TYPES.ADD_STUDENT]: AddStudentModal,
  [MODAL_TYPES.ADD_APPLICATION]: AddApplication,
  [MODAL_TYPES.STUDENT_DOCUMENT_INFO]: StudentDocInfoModal,
  [MODAL_TYPES.MANDATORY_DOC]: ApplicationDocInfoModal,
  [MODAL_TYPES.DOWNLOAD_APPLICATION_CSV]: DownloadApplicationCsv,
  [MODAL_TYPES.PRE_VISA_INFO]: PreVisaInfo,
  [MODAL_TYPES.UPLOAD_AND_MAP_DOCS]: UploadAndMapDocumentsModal,
  [MODAL_TYPES.DOCUMENT_REJECT_MODAL]: DocumentRejectModal,
  [MODAL_TYPES.SHORTLIST_CONDITIONAL_MODAL]: ShortlistConditionalModal,
  [MODAL_TYPES.NO_RESULTS]: NoResults,
  [MODAL_TYPES.SIMILAR_VISA_REQUEST_WARNING_MODAL]:
    SimilarVisaRequestWarningModal,
  [MODAL_TYPES.DELETE_VISA_REQUEST_WARNING_MODAL]:
    DeleteVisaRequestWarningModal,
  [MODAL_TYPES.ADD_BOOKMARK]: AddToBookmark,
  [MODAL_TYPES.CREATE_BOOKMARK_LIST_MODAL]: CreateBookmarkListModal,
  [MODAL_TYPES.COMMISSION_DUPLICATE_STUDENTS_MODAL]:
    CommissionDuplicateStudentsModal,
  [MODAL_TYPES.COMMISSION_VERSION_HISTORY]: CommissionVersionHistoryModal,
  [MODAL_TYPES.STUDENT_COMMISSION_MORE_FILTER]: StudentCommissionMoreFilter,
  [MODAL_TYPES.COMMISSION_BULK_UPDATE_MODAL]: CommissionBulkUpdateModal,
  [MODAL_TYPES.UPDATE_INFO_MODAL]: UpdateInfoModal,
  [MODAL_TYPES.SHORTLIST_REMARKS_MODAL]: ShortlistRemarksModal,
  [MODAL_TYPES.GTE_PROCESS]: GTEModal,
  [MODAL_TYPES.CREATE_CMS_INSTITUTE_MODAL]: CreateCMSInstituteModal,
  [MODAL_TYPES.MARK_PROGRAM_CLOSED_MODAL]: MarkProgramClosedModal,
  [MODAL_TYPES.IELTS_VERIFICATION_MODAL]: IeltsVerificationModal,
  [MODAL_TYPES.UNISETU_DASHBOARD_MORE_FILTER]: UnisetuDashboardMoreFilters,
  [MODAL_TYPES.CSV_EXPORT_MODAL]: CSVExportModal,
  [MODAL_TYPES.VAS_DRAWER]: VASDrawer,
  [MODAL_TYPES.REPORT_COURSE_GLOBAL_MODAL]: ReportCourseGlobalModal,
  [MODAL_TYPES.TASK_EMAIL_EDITOR_MODAL]: TaskEmailEditorModal,
  [MODAL_TYPES.ADD_SUB_AGENT_FORM]: AddSubAgentForm,
  [MODAL_TYPES.DEFFERAL_HISTORY_MODAL]: DeferralHistoryModal,
  [MODAL_TYPES.RECOMMENDED_COURSES_MODAL]: RecommendedCoursesModal,
  [MODAL_TYPES.VAS_MORE_FILTERS]: VASMoreFiltersModal,
  [MODAL_TYPES.EMAIL_AUTOMATION_MORE_FILTERS_MODAL]: MoreFiltersModal,
  [MODAL_TYPES.COUNSELLOR_CSP_PERFORMANCE_DETAILS_MODAL]:
    CounsellorPerformanceCspDetailsModal,
  [MODAL_TYPES.COUNSELLOR_CSP_INITIATE_CALL]: CounsellorInitiateJerryCall,
  [MODAL_TYPES.NEXT_ACTION_MODAL]: NextActionModal,
  [MODAL_TYPES.SUCCESSFUL_UPDATE_MODAL]: SuccessfulUpdateModal,
  [MODAL_TYPES.RAISE_SHORTLIST_REQUEST_MODAL]: RaiseShortlistRequestModal,
  [MODAL_TYPES.EMAIL_CONFIRMATION]: EmailConfirmationModal,
  [MODAL_TYPES.STUDENT_DOWNLOAD_FORM_MODAL]: StudentDownloadFormModal,
  [MODAL_TYPES.ADD_COURSE_TO_SHORTLIST]: AddCourseToShortlist,
  [MODAL_TYPES.CSP_MORE_FILTERS]: CspMoreFiltersModal,
  [MODAL_TYPES.ADDITIONAL_USER_DETAILS]: AdditionalDetailsModal,
  [MODAL_TYPES.SOP_DRAFT_PLAGIARISM_CHECK]: SOPDraftPlagiarismModal,
  [MODAL_TYPES.ADD_MEETING]: AddMeetingModal,
  [MODAL_TYPES.CANCEL_MEETING]: CancelMeetingDialog,
  [MODAL_TYPES.SOP_MORE_FILTERS]: SOPMoreFiltersModal,
  [MODAL_TYPES.PARTNER_PROFILE_MODAL]: PartnerProfileModal,
  [MODAL_TYPES.VOUCHER_STATUS_CONFIRMATION_DIALOG]:
    VoucherStatusConfirmationDialog,
  [MODAL_TYPES.ADD_FOCUSED_UNIVERSITY]: AddFocusedUniversityForm,
  [MODAL_TYPES.ADD_TARGET_MODAL]: AddTargetModal,
  [MODAL_TYPES.APPLICATION_FILING_QC_MODAL]: ApplicationFilingQCModal,
  [MODAL_TYPES.BULK_APPLICATION_TABLE_MODAL]: BulkApplicationTableModal,
};

const GlobalModal = () => {
  const modalsData = useAppSelector((state: RootState) => selectModal(state));

  const renderComponent = (modal: ModalDataType, index: number) => {
    if (modal.modalType === null) return null;
    const ModalComponent = MODALS[modal.modalType];
    if (modal?.modalType === MODAL_TYPES.VAS_DRAWER) {
      return (
        <Drawer open={true} anchor="right">
          <Suspense
            fallback={
              <div className="flex items-center justify-center">
                <CircularProgress />
                Loading modal...
              </div>
            }
          >
            <ModalComponent {...modal.modalProps} />
          </Suspense>
        </Drawer>
      );
    }
    return (
      <Modal
        key={index}
        open={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              <CircularProgress />
              Loading modal...
            </div>
          }
        >
          <Box
            sx={{
              '.MuiButton-contained': { backgroundColor: `#443EFF` },
              height: `100%`,
            }}
          >
            <ModalComponent {...modal.modalProps} />
          </Box>
        </Suspense>
      </Modal>
    );
  };

  return (
    <>
      {modalsData?.map((modal, index) =>
        modal.modalType ? renderComponent(modal, index) : null,
      )}
    </>
  );
};

export default GlobalModal;
