import React, { useEffect, useRef, useState } from 'react';
import { GBTypography } from '@leapfinance/geebee-component-library';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { IconButton } from '@mui/material';

import { useGetPresignedUrlMutation } from '@/services/documents';

import { formatDuration } from './utils';

interface CustomAudioPlayerProps {
  src: string;
  initialDuration: number;
}

const CustomAudioPlayer: React.FC<CustomAudioPlayerProps> = ({
  src,
  initialDuration,
}) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [finalSrc, setFinalSrc] = useState<string>();
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const presignedCache = useRef<Map<string, string>>(new Map());
  const [getPresignedUrlTrigger] = useGetPresignedUrlMutation();
  const handlePresignedUrl = async (rawUrl: string): Promise<string> => {
    const data = await getPresignedUrlTrigger({ url: rawUrl }).unwrap();
    return data?.data || rawUrl;
  };

  useEffect(() => {
    setIsPlaying(false);
    setCurrentTime(0);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, []);

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const getCachedPresignedUrl = async (rawUrl: string): Promise<string> => {
    const cached = presignedCache.current.get(rawUrl);
    if (cached) {
      return cached;
    }

    const newPresignedUrl = await handlePresignedUrl(rawUrl);
    presignedCache.current.set(rawUrl, newPresignedUrl);
    return newPresignedUrl;
  };

  const handlePlayPause = async () => {
    if (!audioRef.current) return;

    if (!isPlaying) {
      const presigned = await getCachedPresignedUrl(src);
      setFinalSrc(presigned);
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
    }
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!audioRef.current) return;

    const newTime = Number(event.target.value);
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  return (
    <div className="flex flex-col w-full">
      <audio
        ref={audioRef}
        src={finalSrc}
        onLoadedMetadata={handleLoadedMetadata}
        onTimeUpdate={handleTimeUpdate}
      />

      <div className="flex items-center gap-4">
        <IconButton onClick={handlePlayPause} className="p-1">
          {isPlaying ? (
            <PauseCircleFilledRoundedIcon />
          ) : (
            <PlayArrowRoundedIcon />
          )}
        </IconButton>

        <input
          type="range"
          min={0}
          max={duration || 0}
          step={0.01}
          value={currentTime}
          onChange={handleSeek}
          style={{ flex: 1 }}
        />

        <div className="min-w-[40px] flex items-start">
          <GBTypography variant="body4">
            {formatDuration(currentTime)} /{` `}
            {formatDuration(initialDuration)}
          </GBTypography>
        </div>
      </div>
    </div>
  );
};

export default CustomAudioPlayer;
