import { FetchArgs } from '@reduxjs/toolkit/query';
import { geebeeRootBaseApiWithTags } from 'services/base/geebeeApi';
import { definitions, paths } from 'types/schema';
import {
  BaseResponse,
  CreateApplicationRequestType,
  GetApplicationAuditLogsResponseType,
  GetApplicationPreVisaResponse,
  GetApplicationRemarksRequestType,
  GetApplicationRemarksResponseType,
  GetApplicationRequestType,
  GetApplicationResponseType,
  GetApplicationResponseV2,
  GetApplicationsPostQcDetailsRequestType,
  GetApplicationsPostQcDetailsResponseType,
  GetApplicationsResponse,
  GetQCUserListResponse,
  ProgramStatusRequestType,
  ProgramStatusResponseType,
  UpdateApplicationPaymentTypeResponseBodyType,
  UpdateApplicationPreVisaRequest,
  UpdateApplicationPreVisaResponse,
  UpdateApplicationRemarkRequestType,
  UpdateApplicationRemarkResponseType,
  UpdateApplicationsPostQcDetailsRequestType,
  UpdateApplicationsPostQcDetailsResponseType,
  UpdateCoursesIntakeStatusRequestType,
  UpdateCoursesIntakeStatusResponseType,
  UpdateSingleCourseIntakeStatusRequestType,
  UpdateSingleCourseIntakeStatusResponseType,
} from 'types/service';

import {
  ApplicationDocumentsResponse,
  CspCollegeSuggestionRequestType,
  CspCollegeSuggestionResponseType,
  StageChangeRequestBodyType,
  StateChangeRequestType,
  StateChangeResponseType,
  UpdateApplicationUsingOfferLetterPathRequestType,
  UpdateApplicationUsingOfferLetterPathResponseType,
} from '../types/Services';

import { documentUploadBaseApi } from './base/documentUpload';
import { transformResponse } from './util';

type GetForwardEmailReceiversPath =
  paths['/applications/forward-email-metadata'];
type GetForwardEmailReceiversRequestType =
  GetForwardEmailReceiversPath['post']['parameters']['body']['dto'];
type GetForwardEmailReceiversResponseType =
  GetForwardEmailReceiversPath['post']['responses']['200']['schema'];

export const applicationApi = geebeeRootBaseApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    //need to change this
    getApplications: builder.mutation<
      GetApplicationsResponse,
      { payload: any }
    >({
      query: ({ payload }) => {
        const queryObj: any = {
          url: `applications/search`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetApplicationsResponse;
        } else {
          return { data } as unknown as GetApplicationsResponse;
        }
      },
    }),
    getApplicationsv2: builder.mutation<
      GetApplicationResponseV2,
      definitions['ApplicationSearchRequestDto']
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `applications/search`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetApplicationResponseV2;
        } else {
          return { data } as unknown as GetApplicationResponseV2;
        }
      },
    }),
    fetchStageGroups: builder.mutation<
      definitions['ResponseWrapper«List«StageGroupDto»»'],
      any
    >({
      query: (payload: any) => ({
        url: `applications/stage-groups`,
        method: `POST`,
        body: payload,
      }),
    }),
    fetchApplicationAuditLogsById: builder.query<
      GetApplicationAuditLogsResponseType,
      number
    >({
      query: (applicationId) => ({
        url: `audit-logs/application/${applicationId}`,
        method: `GET`,
      }),
      providesTags: [`AuditLogs`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetApplicationAuditLogsResponseType;
        } else {
          return { data } as unknown as GetApplicationAuditLogsResponseType;
        }
      },
    }),
    getQcUserList: builder.query<GetQCUserListResponse, void>({
      query: () => `/users/qc-team`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetQCUserListResponse;
        } else {
          return { data } as unknown as GetQCUserListResponse;
        }
      },
    }),
    getApplicationSpecificQcUserList: builder.query<
      GetQCUserListResponse,
      { applicationId: number }
    >({
      query: ({ applicationId }) => `/users/qc-team/${applicationId}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetQCUserListResponse;
        } else {
          return { data } as unknown as GetQCUserListResponse;
        }
      },
    }),
    createApplication: builder.mutation<
      BaseResponse<any>,
      CreateApplicationRequestType
    >({
      query: (payload) => ({
        url: `/applications`,
        method: `POST`,
        body: payload,
      }),
    }),
    getApplicationsDocuments: builder.query<
      ApplicationDocumentsResponse,
      number
    >({
      query: (applicationId) => `v1/documents/application/${applicationId}`,
      providesTags: [`applicationDocuments`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as ApplicationDocumentsResponse;
        } else {
          return {
            data,
          } as unknown as ApplicationDocumentsResponse;
        }
      },
    }),
    getApplicationPreVisa: builder.query<
      GetApplicationPreVisaResponse,
      { applicationId: number }
    >({
      query: ({ applicationId }) => `/applications/${applicationId}/pre-visa`,
      providesTags: [`preVisaStatus`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as GetApplicationPreVisaResponse;
        } else {
          return { data } as unknown as GetApplicationPreVisaResponse;
        }
      },
    }),
    updateApplicationPaymentInfo: builder.mutation<
      UpdateApplicationPaymentTypeResponseBodyType,
      any
    >({
      query: (payload) => {
        const queryObj: any = {
          url: `/payment`,
          body: payload,
          method: `POST`,
        };
        return queryObj;
      },
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateApplicationPaymentTypeResponseBodyType;
        } else {
          return {
            data,
          } as unknown as UpdateApplicationPaymentTypeResponseBodyType;
        }
      },
    }),
    updateApplicationDetails: builder.mutation({
      query: ({ applicationInformation, applicationId }) => {
        const queryObj: any = {
          url: `/applications/update/${applicationId}`,
          body: { applicationInformation },
          method: `PUT`,
        };
        return queryObj;
      },
      invalidatesTags: [`PostQcDetails`],
    }),
    updateApplicationPreVisa: builder.mutation<
      UpdateApplicationPreVisaResponse,
      UpdateApplicationPreVisaRequest
    >({
      query: ({ applicationId, body }) => ({
        url: `/applications/${applicationId}/pre-visa`,
        method: `POST`,
        body,
      }),
      invalidatesTags: [`preVisaStatus`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as UpdateApplicationPreVisaResponse;
        } else {
          return { data } as unknown as UpdateApplicationPreVisaResponse;
        }
      },
    }),
    // /applications/{applicationId}/stage
    updateApplicationStage: builder.mutation<
      StateChangeResponseType,
      { applicationId: number; body: StageChangeRequestBodyType }
    >({
      query: ({ applicationId, body }) => ({
        url: `/applications/${applicationId}/stage`,
        method: `PUT`,
        body,
      }),
      // invalidatesTags: [`preVisaStatus`],
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as StateChangeResponseType;
        } else {
          return { data } as unknown as StateChangeResponseType;
        }
      },
    }),
    getUniversitySuggestions: builder.mutation<
      CspCollegeSuggestionResponseType,
      CspCollegeSuggestionRequestType
    >({
      query: (body) => ({
        url: `/cf/university`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as CspCollegeSuggestionResponseType;
        } else {
          return { data } as unknown as CspCollegeSuggestionResponseType;
        }
      },
    }),
    updateCoursesIntakeStatus: builder.mutation<
      UpdateCoursesIntakeStatusResponseType,
      UpdateCoursesIntakeStatusRequestType
    >({
      query: ({ universityId, dto }) => ({
        url: `/cf/university/${universityId}/course-intake-status`,
        method: `PUT`,
        body: dto,
      }),
      transformResponse: transformResponse,
    }),
    updateSingleCourseIntakeStatus: builder.mutation<
      UpdateSingleCourseIntakeStatusResponseType,
      UpdateSingleCourseIntakeStatusRequestType
    >({
      query: ({ courseId, dto }) => ({
        url: `/cf/course/${courseId}/course-intake-status`,
        method: `PUT`,
        body: dto,
      }),
      transformResponse: transformResponse,
    }),
    getApplicationProgramStatus: builder.query<
      ProgramStatusResponseType,
      ProgramStatusRequestType
    >({
      query: ({ applicationId }) => {
        console.log(applicationId);
        return `/applications/${applicationId}/program-status`;
      },
      transformResponse: transformResponse,
    }),
    getUniversityVendorSuggestions: builder.mutation<
      CspCollegeSuggestionResponseType,
      CspCollegeSuggestionRequestType
    >({
      query: (body) => ({
        url: `/cf/university/vendor`,
        method: `POST`,
        body,
      }),
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as CspCollegeSuggestionResponseType;
        } else {
          return { data } as unknown as CspCollegeSuggestionResponseType;
        }
      },
    }),
    updateApplicationUsingOfferLetter: builder.mutation<
      UpdateApplicationUsingOfferLetterPathResponseType,
      UpdateApplicationUsingOfferLetterPathRequestType
    >({
      query: ({ id, documentId, dto }) => ({
        url: `/applications/${id}/offer-letter/${documentId}`,
        method: `PUT`,
        body: dto,
      }),
      transformResponse: transformResponse,
    }),

    getApplicationData: builder.query<
      definitions['ResponseWrapper«ApplicationDetailsResponseDto»'],
      { applicationId: number }
    >({
      query: ({ applicationId }) => `/applications/v1/${applicationId}`,
      transformResponse: ({ success, data, message }) => {
        if (!success) {
          return {
            data: null,
            message,
            apiError: true,
          } as unknown as definitions['ResponseWrapper«ApplicationDetailsResponseDto»'];
        } else {
          return {
            data,
          } as unknown as definitions['ResponseWrapper«ApplicationDetailsResponseDto»'];
        }
      },
    }),
    getApplicationPostQcDetails: builder.query<
      GetApplicationsPostQcDetailsResponseType,
      GetApplicationsPostQcDetailsRequestType
    >({
      query: ({ id }) => `/applications/${id}/post-qc-details`,
      transformResponse,
      providesTags: [`PostQcDetails`],
    }),
    UpdateApplicationPostQcDetails: builder.mutation<
      UpdateApplicationsPostQcDetailsResponseType,
      UpdateApplicationsPostQcDetailsRequestType
    >({
      query: ({ id, ...body }) => ({
        url: `/applications/${id}/post-qc-details`,
        body,
        method: `PUT`,
      }),
      transformResponse,
      invalidatesTags: [`PostQcDetails`],
    }),
    getEmailsToPrefillInForwardingTask: builder.mutation<
      GetForwardEmailReceiversResponseType,
      GetForwardEmailReceiversRequestType
    >({
      query: (payload) => {
        return {
          url: `/applications/forward-email-metadata`,
          method: `POST`,
          body: payload,
        };
      },
      transformResponse,
    }),
    getSingleApplicationData: builder.query<
      GetApplicationResponseType,
      GetApplicationRequestType
    >({
      query: (payload) => `/applications/${payload?.id}`,
      transformResponse,
    }),
    // applications/${id}/remarks
    getApplicationsRemarks: builder.query<
      GetApplicationRemarksResponseType,
      GetApplicationRemarksRequestType
    >({
      query: ({ id }) => `/applications/${id}/remarks`,
      transformResponse,
    }),
  }),
});

export const applicationDocumentApi = documentUploadBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    addRemarkWithDocument: builder.mutation<
      UpdateApplicationRemarkResponseType,
      UpdateApplicationRemarkRequestType
    >({
      query: ({
        file,
        keyName,
        fileLabel,
        documentS3Url,
        applicationId,
        givenByTeam,
        remark,
        scholarApplicationId,
        sendEmail,
      }) => {
        const formData = new FormData();
        formData.append(`applicationId`, String(applicationId));
        formData.append(`remark`, remark?.trim());
        formData.append(`scholarApplicationId`, String(scholarApplicationId));

        if (sendEmail) {
          formData.append(`sendEmail`, `${sendEmail}`);
        }

        if (file) {
          formData.append(`file`, file as File);
        }
        if (keyName) {
          formData.append(`keyName`, keyName as string);
        }
        if (fileLabel) {
          formData.append(`fileLabel`, fileLabel as string);
        }

        const payload: FetchArgs = {
          url: `/applications/remark`,
          method: `POST`,
          body: formData,
        };
        return payload;
      },
      transformResponse: transformResponse,
    }),
  }),
});

export const {
  useGetApplicationsMutation,
  useGetApplicationsv2Mutation,
  useFetchStageGroupsMutation,
  useGetUniversitySuggestionsMutation,
  useFetchApplicationAuditLogsByIdQuery,
  useGetQcUserListQuery,
  useGetApplicationSpecificQcUserListQuery,
  useCreateApplicationMutation,
  useGetApplicationsDocumentsQuery,
  useGetApplicationPreVisaQuery,
  useUpdateApplicationPreVisaMutation,
  useUpdateApplicationPaymentInfoMutation,
  useUpdateApplicationDetailsMutation,
  useUpdateApplicationStageMutation,
  useUpdateCoursesIntakeStatusMutation,
  useUpdateSingleCourseIntakeStatusMutation,
  useGetApplicationProgramStatusQuery,
  useLazyGetApplicationProgramStatusQuery,
  useGetUniversityVendorSuggestionsMutation,
  useUpdateApplicationUsingOfferLetterMutation,
  useGetApplicationDataQuery,
  useLazyGetApplicationDataQuery,
  useUpdateApplicationPostQcDetailsMutation,
  useGetApplicationPostQcDetailsQuery,
  useGetEmailsToPrefillInForwardingTaskMutation,
  useGetApplicationsRemarksQuery,
} = applicationApi;

export const { useAddRemarkWithDocumentMutation } = applicationDocumentApi;
