export enum WidgetName {
  NOTIFICATION_PERMISSION_TOAST = `Notification Permission Toast`,
  NOTIFICATION_TOAST = `Notification Toast`,
  PREFERRED_INSTITUTE = `Preferred Institute`,
  RESULT_NAV = `Result Nav`,
  RESULT_CARD = `Result Card`,
  FILTER_SIDEBAR = `Filter Sidebar`,
  PAGINATION = `Pagination`,
  NO_RESULT = `No Result`,
  SEARCH_CARD = `Search Card`,
  DOCUMENT_CHIP = `Document Chip`,
  LEADS_AT_RISK = `Leads at Risk`,
  LEADS_AT_RISK_BANNER = `Leads at Risk Banner`,
  STUDENT_CARDS = `Student Cards`,
  FILTER_SECTION = `Filter Section`,
  MORE_ACTIONS = `More Actions`,
  ACTION_CARD = `Action Card`,
  STUDENT_PROFILE_INCOMPLETE = `Student Profile Incomplete`,
  WHY_NON_ELIGIBLE = `Why Non Eligible`,
  COURSE_ELIGIBILITY_CHECK = `Course Eligibility Check`,
  CHECK_ELIGIBLE_COURSES_ON_CF = `Check Eligible Courses On CF`,
  UPLOAD_DOCUMENT = `Upload Document`,
  STUDENT_ELIGIBILITY_TAG = `Student Eligibility Tag`,
  REQUIRED_DOCUMENT_MODAL = `Required Document Modal`,
  TOP_CTA = `Top CTA`,
  ADD_NEW_APPLICATION = `Add New Application`,
  ADD_NEW_STUDENT = `Add New Student`,
  'Actions Required' = `Actions Required`,
  SUMMARY = `Summary`,
  APPLICATION_FILTER = `Application Filter`,
  NEW_DASHBOARD_UNIVERSITY_UPDATES = `University Updates`,
  NEW_DASHBOARD_QUICK_LINKS = `Quick Links`,
  NEW_DASHBOARD_UPCOMING_EVENTS = `Upcoming Events`,
  NEWSLETTER_ICON = `newsletter icon`,
  NEW_DASHBOARD_RM = `Relationship Manager`,
  NEW_DASHBOARD_COUNTRY_EXPERT = `Country Experts`,
  COURSE_LINK_PDF = `Course Link PDF`,
  COURSE_DETAILS_SHORTLIST_MODAL = `Shortlist Modal`,
  COURSE_DETAILS_HEADER = `Course Details Header`,
  COURSE_DETAILS_FOOTER = `Course Details Footer`,
  NO_RESULT_SECTION = `No Result Section`,
  UNIVERSITY_COURSE_TAB = `University/Course Tab`,
  UNIVERSITY_NAME = `University Name`,
  COURSE_COUNT = `Course Count`,
  DEGREE_TAB_UNIVERISTY_PAGE = `Degree tab in university page`,
  UNIVERSITY_WEBSITE = `University Website`,
  UNIVERSITY_RAISE_APPLICATION = `Raise application univeristy page`,
  SELECT_PROGRAM_TOUR = `Select program tour`,
  REQUEST_SHORTLIST_MODAL = `Request Shortlist Modal`,
  CSP_PERFORMANCE_VIEW = `CSP Performance View`,
  CALL_CSP_MODAL = `Call CSP Modal`,
  SHORTLIST_REQUEST = `Shortlist Request`,
  MARK_AS_COMPLETE = `Mark as Complete`,
}

export enum WidgetFormat {
  TOAST = `Toast`,
  CARD = `Card`,
  BANNER = `Banner`,
  MODAL = `Modal`,
  NAVIGATION = `Navigation`,
  SECTION = `Section`,
  DROPDOWN = `Dropdown`,
  SIDEBAR = `Sidebar`,
  PAGINATION = `Pagination`,
  CHIP = `Chip`,
  ACTION_CARD = `Action Card`,
  MORE_ACTIONS_BUTTON = `More Actions Button`,
  WARNING_MESSAGE = `Warning Message`,
  BUTTON = `Button`,
  DOCUMENT_UPLOAD_CHIP = `Document Upload Chip`,
  TAG = `Tag`,
  ICON = `Icon`,
  TAB = `Tab`,
  UNIVERSITY_CARD = `University Card`,
  TOUR = `Tour Selection`,
}

export enum PageNames {
  SPEEDUP_SUMMARY_DASHBOARD = `Speedup Summary Dashboard`,
  COURSE_FINDER = `Course Finder`,
  COURSE_FINDER_RESULTS_PAGE = `Course Finder Results Page`,
  COURSE_DETAILS = `Course Details`,
  PDF_SHORTLIST = `PDF Shortlist`,
  KNOWLEDGE_LIBRARY = `Speedup Knowledge Library`,
  COUNSELLOR_DASHBOARD = `Counsellor Dashboard`,
  STUDENT_PROFILE = `Student Profile`,
}

export type TrackPageProperties = {
  pageName: string;
  pageUrl?: string;
  browser?: string;
  platform?: string;
  userId?: string | number;
  widgetName?: string;
  widgetFormat?: string;
  userEmail?: string;
  notificationType?: string;
  studentId?: number | string;
};

export type TrackClick = {
  pageName: string;
  pageUrl?: string;
  widgetName: WidgetName;
  widgetFormat: WidgetFormat;
  contentName: string;
  contentFormat: string;
  applicationId?: number;
  userEmail?: string;
  userId?: string | number;
  notificationType?: string;
  appliedFilterName?: Record<string, any>;
  category: string;
  meta1: any;
  meta2: any;
  cardName?: string;
  selectedCountry?: string;
  pageNumber?: number;
  downloadFormat?: string;
  courseIds?: number[];
  totalCourses?: number;
  actionType?: string;
  source?: string;
  studentId?: number | string;
  leadStage?: string;
  cspName?: string;
  contactName?: string;
  contactNumber?: string;
  shortlistRequestId?: number;
};

export enum SegmentEventSource {
  CF = `course-finder`,
}
