import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { transformResponse } from '@/utils/common';

import {
  GetUniversityPostQcRequirementKeysPathRequestType,
  GetUniversityPostQcRequirementKeysResponseType,
} from './type';
export const postQcBaseApi = createApi({
  reducerPath: `postQcBaseApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: `/api/geebeeRoute?path=/university-required-info/`,
  }),
  endpoints: () => ({}),
});
const postQcApiWithTags = postQcBaseApi.enhanceEndpoints({});
export const postQcApi = postQcApiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUniversityPostQcRequirementKeys: builder.query<
      GetUniversityPostQcRequirementKeysResponseType,
      GetUniversityPostQcRequirementKeysPathRequestType
    >({
      query: ({ universityId }) => `/post-qc-requirement-keys/${universityId}`,
      transformResponse,
    }),
  }),
});
export const { useGetUniversityPostQcRequirementKeysQuery } = postQcApi;
