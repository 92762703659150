import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import {
  isFalsy,
  SuccessScreen,
  useFinalFormYupFormHelpers,
} from '@leapfinance/frontend-commons';
import {
  Button,
  FFCreatableDropdown,
  FFDropdown,
  FFMobileInput,
  FFRadioGroup,
  FFTextInput,
  GBTypography,
} from '@leapfinance/geebee-component-library';
import Close from '@mui/icons-material/Close';
import { Alert, Divider, IconButton, Radio } from '@mui/material';
import { useSnackbar } from 'notistack';

import { trackClick } from '@/segment/eventTracker';
import {
  useCreateInitiateCallMutation,
  useCreateQuackerContactMutation,
  useGetQuackerLabelOptionsMutation,
  useGetQuackerTypeOptionsQuery,
} from '@/services/quackerApi';
import { PageNames, WidgetFormat, WidgetName } from '@/types/segmentEvents';
import { CreateQuackerContactRequestType } from '@/types/Services';

import useModal from '../modals/useModal';

import {
  addQuackerContactSchema,
  MOBILE_NUMBER_ALREADY_EXIST_ERROR_MESSAGE,
} from './addContact.schema';

type Props = {
  src?: 'counsellor-csp-dashboard' | 'quacker-endpoint';
  initialValues?: {
    name?: any;
    phoneNumber?: any;
    label?: any;
    type?: any;
  };
  onFormSubmit?: () => void;
  onExistingStudentError?: (studentNumber: string, tabValue: number) => void;
  cspName?: string;
  disableCspName?: boolean;
};
function AddContactForm({
  src = `quacker-endpoint`,
  initialValues,
  onFormSubmit,
  onExistingStudentError,
  cspName,
  disableCspName = false,
}: Props) {
  const { hideModal } = useModal();
  const [validate, formName] = useFinalFormYupFormHelpers({
    schema: addQuackerContactSchema,
  });
  const [savedContact, setSavedContact] = useState<
    | {
        number: string;
        name: string;
      }
    | undefined
  >();
  const [triggerCreateContact, { isLoading }] =
    useCreateQuackerContactMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [triggerInitiateCall, { isLoading: isCallingCsp }] =
    useCreateInitiateCallMutation();
  const [triggerFetchLabelOptions] = useGetQuackerLabelOptionsMutation();
  const { data: typeOptionsData } = useGetQuackerTypeOptionsQuery();

  const fetchGeebeeContacts = async () => {
    const data = await triggerFetchLabelOptions({
      contactType: `CSP`,
    }).unwrap();
    setLabelOptions({
      CSP: data?.data ?? [],
      GEEBEE: [],
    });
  };

  useEffect(() => {
    if (src === `counsellor-csp-dashboard`) {
      fetchGeebeeContacts();
    }
  }, []);

  const [labelOptions, setLabelOptions] = useState<{
    CSP: string[];
    GEEBEE: string[];
  }>({ CSP: [], GEEBEE: [] });

  const typeOptions = typeOptionsData?.data ?? [];

  const handleInitiateCall = async () => {
    if (!savedContact?.number) return;

    const response = await triggerInitiateCall({
      contactNumber: savedContact?.number,
    }).unwrap();

    if (response?.success) {
      enqueueSnackbar(`Call initiated successfully`, {
        variant: `success`,
      });
      hideModal();
    }
  };

  const handleFormSubmit = (values: any) => {
    const payload: CreateQuackerContactRequestType = {
      label: values?.label?.__isNew__
        ? values?.label?.inputValue
        : values?.label,
      name: values?.name,
      phoneNumber: `${values?.phoneNumber?.countryCode}${values?.phoneNumber?.number}`,
      type: values?.type?.__isNew__ ? values?.type?.inputValue : values?.type,
    };
    if (src === `counsellor-csp-dashboard`) {
      trackClick({
        pageName: PageNames.COUNSELLOR_DASHBOARD,
        contentName: `Save and Initiate Call`,
        contentFormat: `Button`,
        widgetFormat: WidgetFormat.MODAL,
        widgetName: WidgetName.CALL_CSP_MODAL,
        contactName: values?.name,
        contactNumber: `${values?.phoneNumber?.countryCode}${values?.phoneNumber?.number}`,
        cspName: cspName,
      });
      triggerCreateContact(payload)
        .unwrap()
        .then((data) => {
          if (data?.success) {
            enqueueSnackbar(`Contact added successfully`, {
              variant: `success`,
            });
            // saveAndInitiateCallButtonCallback?.();
            setSavedContact({
              number: payload?.phoneNumber,
              name: payload?.name,
            });
          }
        });
    } else {
      triggerCreateContact(payload)
        .unwrap()
        .then((data) => {
          if (data?.success) {
            enqueueSnackbar(`Contact added successfully`, {
              variant: `success`,
            });
            hideModal();
          }
        });
    }
  };

  const quackerFormInitialValue = React.useMemo(
    () => ({
      name: initialValues?.name,
      label: initialValues?.label,
    }),
    [initialValues],
  );

  return (
    <Form
      onSubmit={handleFormSubmit}
      validate={validate}
      initialValues={quackerFormInitialValue}
      render={({ form, values, handleSubmit, errors }) => (
        <div
          className={`bg-white-0 rounded-lg flex flex-col  ${
            src === `quacker-endpoint` ? `w-[421px]` : `w-full pt-6`
          }`}
        >
          {src === `quacker-endpoint` && (
            <>
              <div className="py-3 px-4 flex items-center justify-between">
                <GBTypography variant="sh1">Add Contact</GBTypography>
                <IconButton onClick={() => hideModal()}>
                  <Close />
                </IconButton>
              </div>
              <Divider />
            </>
          )}
          <div
            className={`flex flex-col ${
              src === `quacker-endpoint` ? `p-4 gap-4` : `gap-6 `
            }`}
          >
            {!savedContact ? (
              <>
                {` `}
                {src === `quacker-endpoint` ? (
                  <Alert
                    severity="info"
                    icon={
                      <i className="fi fi-rr-square-info text-gbTokens-cloud-400 self-center"></i>
                    }
                    className={`bg-gbTokens-cloud-100 border border-gbTokens-cloud-200`}
                  >
                    <GBTypography variant="caption1">
                      Adding contacts will create a contact on Jerry
                      automatically, from where you can directly make calls to
                      this contact.
                    </GBTypography>
                  </Alert>
                ) : (
                  <div className="bg-gbTokens-teal-100 rounded-md border border-gbTokens-cloud-200 py-1 px-2 flex items-start gap-x-2 mx-4">
                    <i className="fi fi-rr-square-info text-gbTokens-cloud-400 w-4 h-4"></i>
                    <GBTypography variant="caption1">
                      Adding contacts will create a contact on Jerry
                      automatically, from where you can directly make calls to
                      this contact.
                    </GBTypography>
                  </div>
                )}
                <div
                  className={
                    src === `quacker-endpoint`
                      ? `flex flex-col gap-2`
                      : `grid grid-cols-2 gap-x-2 gap-y-4 px-4`
                  }
                >
                  <FFTextInput
                    fieldProps={{ name: formName.name }}
                    componentProps={{
                      required: true,
                      label: `Contact Name`,
                      placeholder: `Enter Contact Name`,
                    }}
                  />
                  <FFMobileInput
                    fieldProps={{ name: formName.phoneNumber }}
                    componentProps={{
                      required: true,
                      label: `Mobile Number`,
                      placeholder: `Enter Mobile number`,
                      ...(src === `counsellor-csp-dashboard` &&
                      errors?.[formName.phoneNumber]?.[0] ===
                        MOBILE_NUMBER_ALREADY_EXIST_ERROR_MESSAGE
                        ? {
                            errorCta: (
                              <GBTypography
                                variant="caption1"
                                onClick={() => {
                                  onExistingStudentError?.(
                                    (values?.[formName.phoneNumber] as any)
                                      ?.number,
                                    1,
                                  );
                                }}
                                className="text-gbTokens-primary-500 transform-none not-italic hover:underline no-underline cursor-pointer"
                              >
                                View CSP Contact Details
                              </GBTypography>
                            ),
                          }
                        : {}),
                    }}
                  />

                  {src === `counsellor-csp-dashboard` && (
                    <FFDropdown
                      fieldProps={{ name: formName.label }}
                      componentProps={{
                        options: labelOptions.CSP,
                        textInputProps: {
                          label: `CSP Name`,
                          placeholder: `Enter CSP name`,
                          required: true,
                        },
                        disableClearable: true,
                        enableVirtualization: true,
                        disabled: disableCspName,
                      }}
                    />
                  )}

                  <FFCreatableDropdown
                    fieldProps={{ name: formName.type }}
                    componentProps={{
                      options: typeOptions,
                      textInputProps: {
                        label: `Location/Additional Flag`,
                        placeholder: `Enter Location/Additional Flag`,
                        required: true,
                      },
                      disableClearable: true,
                      enableVirtualization: true,
                    }}
                  />

                  {src !== `counsellor-csp-dashboard` && (
                    <FFRadioGroup
                      fieldProps={{
                        name: formName.cspTeamName,
                        validate: (value) => {
                          if (src === `quacker-endpoint` && !value) {
                            return `CSP/Team Name is Required`;
                          }
                          return null;
                        },
                      }}
                      componentProps={{
                        options: [
                          { label: `CSP`, value: `CSP` },
                          { label: `Internal Team`, value: `GEEBEE` },
                        ],
                        label: `CSP/Team Name`,
                        required: true,
                        onChange: async (_, value) => {
                          const selected = value.value;
                          form.change(formName.label, undefined);

                          if (
                            isFalsy(
                              labelOptions?.[selected as 'CSP' | 'GEEBEE'],
                            )
                          ) {
                            const options = await triggerFetchLabelOptions({
                              contactType: selected,
                            }).unwrap();
                            setLabelOptions((prev) => {
                              return { ...prev, [selected]: options?.data };
                            });
                          }
                        },
                      }}
                    />
                  )}

                  {(values?.cspTeamName as any)?.value === `CSP` && (
                    <FFDropdown
                      fieldProps={{ name: formName.label }}
                      componentProps={{
                        options: labelOptions.CSP,
                        textInputProps: {
                          label: `CSP Name`,
                          placeholder: `Enter CSP name`,
                          required: true,
                        },
                        disableClearable: true,
                        enableVirtualization: true,
                      }}
                    />
                  )}
                  {(values?.cspTeamName as any)?.value === `GEEBEE` && (
                    <FFCreatableDropdown
                      fieldProps={{ name: formName.label }}
                      componentProps={{
                        options: labelOptions.GEEBEE,
                        textInputProps: {
                          label: `Internal Team Name`,
                          placeholder: `Enter Internal Team Name`,
                          required: true,
                        },
                        disableClearable: true,
                        enableVirtualization: true,
                      }}
                    />
                  )}
                </div>
              </>
            ) : (
              <div className="px-16 text-center">
                <SuccessScreen
                  title={`Contact added successfully for ${savedContact?.name} - ${savedContact?.number}`}
                />
              </div>
            )}
            {src === `quacker-endpoint` && <Divider />}

            <div
              className={
                src === `quacker-endpoint`
                  ? ``
                  : `flex items-center justify-end px-4 py-3 border-t`
              }
            >
              {src === `counsellor-csp-dashboard` ? (
                !savedContact ? (
                  <Button
                    className="py-3 px-4"
                    loading={isLoading}
                    // @ts-ignore
                    disabled={Object.keys(errors)?.length > 0}
                    onClick={handleSubmit}
                  >
                    Add Contact
                  </Button>
                ) : (
                  <Button
                    className="py-3 px-4"
                    loading={isCallingCsp}
                    // @ts-ignore
                    disabled={!savedContact?.number}
                    onClick={handleInitiateCall}
                  >
                    Initiate Call
                  </Button>
                )
              ) : (
                <Button
                  fullWidth
                  className="py-3 px-4"
                  loading={isLoading}
                  onClick={handleSubmit}
                >
                  Add Contact
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    />
  );
}

export default AddContactForm;
